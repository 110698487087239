<template>
<section class="price">
   <div class="banner" :class="{'enbanner':$i18n.locale == 'en'}">
      <a :href="url">{{ $t('PC.Product.Slager.Price.shi-yong-slager') }}</a>
   </div>
   <div class="main">
      <div class="pm">
         <div class="pm_box pb1">
            <div class="box_top">
               <p class="title">{{ $t('PC.Product.Slager.Price.mian-fei-ban') }}</p>
               <p class="set_price"><i>￥</i>0<i>{{ $t('PC.Product.Slager.Price.yue') }}</i></p>
               <p class="subtitle">{{ $t('PC.Product.Slager.Price.xian-shi-mian-fei-shi-yong') }}</p>
               <div class="price_tag">{{ $t('PC.Product.Slager.Price.xian-shi-mian-fei') }}</div>
            </div>
            <div class="box_bottom">
               <p>{{ $t('PC.Product.Slager.Price.zui-da-ge-xiang-mu-ge-shu') }} <i>7</i></p>
               <p>{{ $t('PC.Product.Slager.Price.mo-ban-ku-mo-ban-mian-fei-shi-yong') }} <i class="iconfont iconslagervip_duihao"></i></p>
               <p>{{ $t('PC.Product.Slager.Price.gong-shi-shi-bie') }} <i class="iconfont iconslagervip_wrong"></i></p>
               <p>Word 2 LaTeX <i class="iconfont iconslagervip_wrong"></i></p>
               <p>{{ $t('PC.Product.Slager.Price.you-xian-ji-shu-zhi-chi') }} <i class="iconfont iconslagervip_wrong"></i></p>
            </div>
         </div>
         <div class="pm_box pb2">
            <div class="box_top">
               <p class="title">{{ $t('PC.Product.Slager.Price.fu-fei-ban') }}</p>
               <p class="set_price"><i>￥</i>26<i>{{ $t('PC.Product.Slager.Price.yue') }}</i></p>
               <p class="subtitle">{{ $t('PC.Product.Slager.Price.xian-zai-gou-mai-fu-fei-ban') }} <br /> {{ $t('PC.Product.Slager.Price.nian-fu-ke-xiang-shou-xian-shi-qi-liu-zhe-you-hui') }}</p>
               <div class="price_tag">{{ $t('PC.Product.Slager.Price.nian-fu-qi-liu-zhe') }}</div>
            </div>
            <div class="box_bottom">
               <p>{{ $t('PC.Product.Slager.Price.zui-da-ge-xiang-mu-ge-shu') }} <i class="iconfont iconslagervip_wuxian"> {{ $t('PC.Product.Slager.Price.wu-xian') }}</i></p>
               <p>{{ $t('PC.Product.Slager.Price.mo-ban-ku-mo-ban-mian-fei-shi-yong') }} <i class="iconfont iconslagervip_duihao"></i></p>
               <p>{{ $t('PC.Product.Slager.Price.gong-shi-shi-bie') }} <i class="iconfont iconslagervip_duihao"></i></p>
               <p>Word 2 LaTeX <i class="iconfont iconslagervip_duihao"></i></p>
               <p>{{ $t('PC.Product.Slager.Price.you-xian-ji-shu-zhi-chi') }} <i class="iconfont iconslagervip_duihao"></i></p>
            </div>
         </div>
      </div>
      <FooterC></FooterC>
   </div>
</section>
</template>

<script>
import FooterC from "./components/FooterC";
export default {
   components: {
      FooterC,
   },
   data() {
      return {
         url: Global.SLAGER_URL
      }
   },
};
</script>

<style lang="scss" scoped>
.price {
   margin-top: 70Px;
   background-color: #f1f2f6;

   .banner {
      width: 100%;
      display: block;
      position: absolute;
      left: 50%;
      top: 70Px;
      transform: translate(-50%, 0);
      overflow: hidden;
      background-image: url("../../assets/zh/images/banner1.jpg");
      background-repeat: no-repeat;
      background-size: contain;
      width: 1920Px;
      height: 440Px;

      a {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 256Px;
         left: 480Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #fff;

         &:hover {
            cursor: pointer;
         }
      }

      .price {
         font-family:   PingFangSC-Semibold, PingFang SC;
         position: absolute;
         top: 236Px;
         left: 617Px;
         width: 120Px;
         height: 36Px;
         line-height: 36Px;
         background: transparent;
         border-radius: 2Px;
         box-sizing: border-box;
         text-align: center;
         font-size: 14Px;
         color: #fff;
         border: 1Px solid #ffffff;

         &:hover {
            cursor: pointer;
         }
      }
   }

   .enbanner {
      background-image: url("../../assets/en/images/banner1.jpg");
   }

   .main {
      position: absolute;
      top: 510Px;
      width: 100%;
      height: 593Px;

      .pm {
         margin: 0 auto;
         height: 593Px;
         width: auto;
         display: flex;
         justify-content: center;
         align-items: center;

         .pm_box {
            width: 300Px;
            background: #FFFFFF;
            box-shadow: 0Px 2Px 28Px 1Px #DEDFE6;
            border-radius: 8Px;
            overflow: hidden;
            color: #3D4654;

            .box_top {
               height: 227Px;
               background-image: url("../../assets/images/dingjia_bg1.jpg");
               background-repeat: no-repeat;
               background-size: 110%;
               background-position: center center;
               position: relative;

               .title {
                  padding-top: 40Px;
                  text-align: center;
                  font-size: 24Px;
                  font-family: PingFangSC-Medium, PingFang SC;
                  line-height: 33Px;
                  font-weight: 900;
               }

               .subtitle {
                  text-align: center;
                  font-size: 12Px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  line-height: 17Px;
               }

               .set_price {
                  font-size: 46Px;
                  line-height: 65Px;
                  text-align: center;

                  i {
                     height: 20Px;
                     font-size: 14Px;
                     font-family:   PingFangSC-Regular, PingFang SC;
                     line-height: 20Px;
                     font-weight: normal;
                  }

                  .yue {
                     font-size: 14Px;
                  }
               }

               .price_tag {
                  padding: 8Px 10Px;
                  box-sizing: border-box;
                  background: #F85B2D;
                  border-radius: 8Px 0Px 8Px 0Px;
                  font-size: 12Px;
                  font-family: PingFangSC-Semibold, PingFang SC;
                  color: #FFFFFF;
                  line-height: 17Px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  left: 0;
                  top: 0;
               }
            }

            .box_bottom {
               padding: 20Px;
               box-sizing: border-box;

               p {
                  font-size: 14Px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  line-height: 20Px;
                  margin-bottom: 16Px;
                  display: flex;
                  justify-content: space-between;
               }

            }
         }

         .pb1 {
            margin-right: 48Px;

            .box_top {
               background-image: url("../../assets/images/dingjia_bg1.jpg");
            }

            .box_bottom {
               p {
                  i {
                     width: 16Px;
                     text-align: center;
                  }
               }
            }

         }

         .pb2 {
            .box_top {
               color: #805424;
               background-image: url("../../assets/images/dingjia_bg2.jpg");
            }

            .box_bottom {
               p {
                  i {
                     color: #B98C5E;
                  }
               }
            }
         }
      }
   }
}
</style>
